import { Component, OnInit } from '@angular/core';
import { NavParams } from '@ionic/angular';
import { AlertController, LoadingController, ToastController, ModalController } from '@ionic/angular';

@Component({
  selector: 'app-scheduled-call-details',
  templateUrl: './scheduled-call-details.page.html',
  styleUrls: ['./scheduled-call-details.page.scss'],
})
export class ScheduledCallDetailsPage implements OnInit {

  public callsDetails = this.navParams.get('scheduled_call_details');
  public type = this.navParams.get('type');

  constructor(
     private navParams: NavParams,
     public modalController : ModalController,
    ) { 
    console.log("type: ",this.type);
  }

  ngOnInit() {
  }


  async closeModal(action) {
    await this.modalController.dismiss({ action: action });
  }

}
