import { Component } from '@angular/core';
import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Router, ActivatedRoute, NavigationExtras } from "@angular/router";

import { AlertController, LoadingController, NavController, MenuController, ToastController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { ServiceForAllService } from './service-for-all.service';
import { Events } from './events';

import * as firebase from 'firebase';
import * as moment from 'moment';
import { Device } from '@ionic-native/device/ngx';
import { OneSignal } from '@ionic-native/onesignal/ngx';

// import { AdMobFree, AdMobFreeBannerConfig } from '@ionic-native/admob-free/ngx';

var obj = {};

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  public appPages = [];
  role: any;
  user: any = [];
  deviceData: any = [];
  stras_width: any = ''
  unread_count: number;
  data_notification: any = [];
  redirect_uri: any;
  chat_count: number;
  currentuser_info: any = [];
  userID: any;
  res: any = [];
  ref: any = [];
  ref2: any = [];
  ref3: any = [];
  tmp1: any = [];
  myRef: any = [];
  myRef2: any = [];
  caller_data:any;
  count: any = 0;
  status:any;
  ready:boolean=false;
  highest_position:string;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private router: Router,
    public alertController: AlertController,
    public storage: Storage,
    public menu: MenuController,
    public navCtrl: NavController,
    public toastCtrl: ToastController,
    public serviceForAllService: ServiceForAllService,
    public events: Events,
    private device: Device,
    public oneSignal: OneSignal,
    // private admobFree: AdMobFree
  ) {
    this.redirect_uri = '/tabs/home';
    this.unread_count = 0;
    this.chat_count = 0;
    this.initializeApp();

    this.storage.get('user').then((user) => {
      if (user != null) {
        this.user = user;
        this.user = user;

        if(this.user.highest_position=='executive_not_financial'){
          this.highest_position =  this.user.highest_postion_value;
        }else{
           this.highest_position = this.user.highest_postion_value;
        }
        console.log("this.user = ",this.user);
        this.initializeMenu(this.user);
      }
    });
    window.addEventListener('keyboardDidHide', () => {
      // this.addAdsGoogle();
      // console.log('keyboard =hide');
    });
    
  }


  initializeApp() {
    let _self = this;
    this.platform.ready().then(() => {

      if (this.platform.is('cordova')) {
        // console.log('cordova platform');
        // this.addAdsGoogle();
      }

      // this.router.navigateByUrl('/tabs/dashboard');
      this.statusBar.styleDefault();
      this.statusBar.overlaysWebView(false);
      this.statusBar.backgroundColorByHexString('#0b1325');
      this.statusBar.styleLightContent();
      this.splashScreen.hide();
      
      this.storage.get('user').then((val) => {
        if (val != null) {
          this.getUserData();
          this.navCtrl.navigateRoot('/tabs/dashboard');
        } else {
          this.storage.remove('user');
          this.router.navigate(['/login']);
        }
      });

      this.events.subscribe('user:login', (data: any) => {
        // console.log('Welcome', data.user, 'at', data.time);
        this.initializeMenu(data.user);
        console.log("events subscribe compr= ",data.user)
    
        this.getUserData();
      });
      this.events.subscribe('user:profile_updated2', (check) => {
        this.getUserData();
      });
      this.events.subscribe('user:profile_updated', (check) => {
        this.getUserData();
      });

      // Your web app's Firebase configuration
      var firebaseConfig = {
        apiKey: "AIzaSyACNdza19azHwkPlA8QF8Y771aYxAUl3Xo",
        authDomain: "ceo-discovery.firebaseapp.com",
        projectId: "ceo-discovery",
        storageBucket: "ceo-discovery.appspot.com",
        messagingSenderId: "242926698984",
        appId: "1:242926698984:web:284594daa557bb419da45d"
      };
      // Initialize Firebase
      firebase.initializeApp(firebaseConfig);
      

      // this.oneSignal.startInit('341ca74b-ed63-4774-b5c0-0ef612e113cb', '911825950839');
      this.oneSignal.startInit('2b4f2c07-9d62-4854-bc48-5e22a5058110', '242926698984');

      this.oneSignal.inFocusDisplaying(this.oneSignal.OSInFocusDisplayOption.Notification);

      this.oneSignal.handleNotificationReceived().subscribe((data) => {
        // alert('hadle alarm');
        console.log('handleNotificationReceived', data);

        this.storage.get('user').then((val) => {
          if (val != null) {
            this.serviceForAllService.getNotCount(val.token)
              .subscribe(res => {

                let res2: any = [];
                res2 = res;
                // console.log("my_noti_count: ", res2.my_noti_count);
                this.unread_count = res2.my_noti_count;
                this.events.publish('mynoti:created', res2.my_noti_count);
              });
          }
        });
      });

      this.oneSignal.handleNotificationOpened().subscribe((data) => {
        // do something when a notification is opened
        this.storage.get('user').then((val) => {
          // console.log('value', val);
          if (val != null) {
            console.log('handleNotificationReceived2', data);
            this.data_notification = data;
            let noti_type:any='';
            let postid:number;
            let reciver_id:any;

            this.redirect_uri = this.data_notification.notification.payload.additionalData.redirect_uri;
            noti_type = this.data_notification.notification.payload.additionalData.noti_type;
            postid = this.data_notification.notification.payload.additionalData.post_id;
            reciver_id = this.data_notification.notification.payload.additionalData.reciver_id;
            // console.log('redirect_uri', this.redirect_uri);

            if ((postid > 0) && (noti_type == 'activity' || noti_type == 'activity_like' || noti_type == 'activity_comment' || noti_type == 'activity_comment_like' || noti_type == 'activity_comment_reply')) {
              let queryVars: any = [];
              queryVars['post_id'] = postid;
              let navigationExtras: NavigationExtras = {
                queryParams: queryVars
              };
              this.router.navigate(['/activity-single'], navigationExtras);
            }
            
            if (noti_type == 'follow') {
              let queryVars: any = [];
              queryVars['uid'] = reciver_id;
              let navigationExtras: NavigationExtras = {
                queryParams: queryVars
              };
              this.router.navigate(['/followers'], navigationExtras);
            }
            if (noti_type == 'connect') {
              this.router.navigate(['/request-list']);
            }
        
            if (noti_type == 'user_chat' || noti_type == 'rating' ) {
              this.router.navigate([this.redirect_uri]);
            }

            if (noti_type == 'call') {
              this.router.navigate(['/tabs/connections']);
            }
            if(noti_type == 'invite_sposor_page' || noti_type == 'resume_sposor_page' || noti_type == 'suspend_sposor_page'){
              this.router.navigate(['/sponsors-list']);
            }
            if (noti_type == 'accept_invitaion') {
              let queryVars: any = [];
              queryVars['type'] = 'my_professionals';
              let navigationExtras: NavigationExtras = {
                queryParams: queryVars
              };
              this.router.navigate(['/professional-list'], navigationExtras);
            }


            // if (this.redirect_uri == '' || this.redirect_uri == null) {
            //   this.redirect_uri = '/tabs/home';
            // }
            // this.router.navigate([this.redirect_uri]);

          } else {
            this.presentToast('Please login to check notification details');
          }
        });

      });

      this.oneSignal.endInit();
    });

    this.storage.get('user').then((val) => {
      if (val != null) {
        this.validateData(val);
      }
    });

  }

  initializeMenu(user) {
    this.checkIncomingCall();
    
    this.events.subscribe('mynoti:created', (c) => {
      this.unread_count = c;
    });

    this.events.subscribe('chat_count:created', (c) => {
      this.chat_count = c;
      // console.log("chat_count on app component page: ", this.chat_count);

    });

    if (user) {
      this.serviceForAllService.getNotCount(user.token).subscribe(res => {
        let res2: any = [];
        res2 = res;
        // console.log("my_noti_count: ", res2.my_noti_count);
        this.unread_count = res2.my_noti_count;
        this.events.publish('mynoti:created', res2.my_noti_count);
      });
      this.get_unread_chat_count();
    }

    this.user = user;
    this.role = this.user.role;
    if (this.user) {
      console.log("User Role is === ",this.user.role);
      if(this.user.role == 'financial_sponsor'){
        
        this.appPages = [
          {
            title: 'Dashboard',
            url: '/tabs/dashboard',
            icon: 'home',
            icon_url: ''
          },
          {
            title: 'My Profile',
            url: '/tabs/profile',
            icon: 'person',
            icon_url: ''
          },
          {
            title: 'Search Professionals',
            url: '/tabs/search/executive',
            icon: 'search',
            icon_url: ''
          },
          {
            title: 'Search Financial Sponsors',
            url: '/tabs/search/financial_sponsor',
            icon: 'search',
            icon_url: ''
          },
          {
            title: 'Connect Request',
            url: '/request-list',
            icon: 'person',
            icon_url: ''
          },
          {
              title: 'Notifications',
              url: '/tabs/notification',
              icon: 'notifications-outline',
              icon_url: ''
          },
          {
            title: 'FAQ',
            url: '/faq',
            icon:'help-circle-outline',
            icon_url: ''
          },
          {
            title: 'Settings',
            url: '/settings',
            icon: 'settings',
            icon_url: ''
          },
          {
            title: 'Solutions & Support',
            url: '/contactus',
            icon: 'mail',
            icon_url: ''
          },
          {
            title: 'Terms & Conditions',
            url: '/terms',
            icon: 'document',
            icon_url: ''
          },
          {
            title: 'Privacy Statement',
            url: '/privacy-statement',
            icon: 'document',
            icon_url: ''
          },
        ];
      }
      if(this.user.role == 'executive'){

        this.appPages = [
          {
            title: 'Dashboard',
            url: '/tabs/dashboard',
            icon: 'home',
            icon_url: ''
          },
          // {
          //   title: 'Call-feedback',
          //   url: '/call-feedback',
          //   icon: 'home',
          //   icon_url: ''
          // },
          {
            title: 'My Profile',
            url: '/tabs/profile',
            icon: 'person',
            icon_url: ''
          },
          {
            title: 'Sponsors List',
            url: '/sponsors-list',
            icon: 'person',
            icon_url: ''
          },
          {
            title: 'Connect Request',
            url: '/request-list',
            icon: 'person',
            icon_url: ''
          },
          {
            title: 'Search Professionals',
            url: '/tabs/search/executive',
            icon: 'search',
            icon_url: ''
          },
          {
            title: 'Search Financial Sponsors',
            url: '/tabs/search/financial_sponsor',
            icon: 'search',
            icon_url: ''
          },
          {
              title: 'Notifications',
              url: '/tabs/notification',
              icon: 'notifications-outline',
              icon_url: ''
          },
          {
            title: 'FAQ',
            url: '/faq',
            icon:'help-circle-outline',
            icon_url: ''
          },
          {
            title: 'Settings',
            url: '/settings',
            icon: 'settings',
            icon_url: ''
          },
          {
            title: 'Solutions & Support',
            url: '/contactus',
            icon: 'mail',
            icon_url: ''
          },
          {
            title: 'Terms & Conditions',
            url: '/terms',
            icon: 'document',
            icon_url: ''
          },
          {
            title: 'Privacy Statement',
            url: '/privacy-statement',
            icon: 'document',
            icon_url: ''
          },
        ];
      }
        
    } else {
      this.appPages = [];
    }
  }

  getUserData() {
    this.serviceForAllService.getUser(this.user.token).subscribe((result) => {
      // this.loading.dismiss();
      let res: any = [];
      res = result;
      if (res.user_data) {
          this.user = res.user_data;
          if(this.user.highest_position=='executive_not_financial'){
            this.highest_position =  this.user.highest_postion_value;
          }else{
             this.highest_position = this.user.highest_postion_value;
          }
          this.storage.set('user', this.user);
        
      }
    }, (err) => {
      // this.loading.dismiss();
      console.log("error...", err);
      let msg = err.error.errormsg;
      this.storage.remove('user');
      this.router.navigate(['/login']);
    });
  }

  closeModal() {
    this.menu.close();
  }

  async goto_profile_page() {
    this.menu.close();
    this.router.navigate(['/tabs/profile']);
  }

  async logout() {
    const alert = await this.alertController.create({
      header: 'Logout',
      message: 'Are you sure ?',
      buttons: [
        {
          text: 'No',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            // console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'Yes',
          handler: () => {
            this.storage.get('user').then((val) => {
              if (val != null) {
                let user_id = val.user_id;
                this.removeToken(user_id);
              }
            });

            this.storage.remove('user');
            this.user = '';
            // this.events.publish('user:created', 'userNotLogin');
            this.events.publish('user:logged-out', {
              user: this.user,
              time: new Date()
            });

            this.menu.close();
            this.navCtrl.navigateRoot('/login');
          }
        }
      ]
    });
    await alert.present();
  }

  validateData(val) {
    this.serviceForAllService.validateAuthToken(val.token)
      .subscribe(res => {
        // console.log('valid User');
      },
        (err) => {
          console.log(err);
          this.removeToken(val.user_id);

          this.storage.remove('user');
          this.presentToast('Session expired. Please login again.');
          this.events.publish('userCheck:created', 'userNotLogin');
          this.router.navigate(['']);
        });
  }

  removeToken(user_id) {
    this.deviceData.push({
      uuid: this.device.uuid,
      model: this.device.model,
      platform: this.device.platform,
      version: this.device.version,
    });
    this.serviceForAllService.updateToken(user_id, '', this.deviceData, 'logout')
      .subscribe(res => {
        console.log(res);
      });
  }

  get_unread_chat_count() {
    this.chat_count = 0;
    this.storage.get('user').then((val) => {
      // console.log(val);
      if (val != null) {
        this.serviceForAllService.getCurrentUserInfo(val.token).subscribe((result) => {
          let res: any = [];
          res = result;
          this.currentuser_info = res.result;
          this.userID = res.result.id;
          this.myRef = firebase.database().ref('chatbox/' + this.userID);
          this.myRef.on('value', (data) => {
            data.forEach((data1) => {
              this.myRef2 = firebase.database().ref('chatbox/' + this.userID + '/' + data1.key).orderByChild('unread').equalTo(1);
              this.myRef2.on('value', (data) => {
                data.forEach((data1) => {
                  if (data1.val().unread == 1) {
                    this.events.publish('chat_count:created', 1);
                  }
                });
              });
            });
          });
        },
          err => {
            console.log(err);
          });
      }
    })
  }

  async presentToast(msg) {
    const toast = await this.toastCtrl.create({
      message: msg,
      animated: true,
      cssClass: "my-toast-red",
      duration: 2000
    });
    toast.present();
  }

  checkIncomingCall(){
    firebase.database().ref('call/'+this.user.user_id).on('child_added', data => {
      console.log(data.val());
      if(data.val()!==undefined){
        this.caller_data=data.val();
        this.status=data.val().status;
        this.ready=true;
        let extra_param: any=[];
        extra_param['token'] = this.caller_data.token;
        extra_param['session_id'] = this.caller_data.session_id;
        extra_param['caller_id'] = this.user.user_id;
         extra_param['id'] = this.caller_data.id;
        extra_param['caller_name'] = this.caller_data.user_name;
        extra_param['caller_img'] = this.caller_data.user_img;
        extra_param['status'] = this.caller_data.status;
        extra_param['booked_id'] = this.caller_data.booking_id;
        extra_param['key'] = data.key;
        extra_param['isCallType'] = this.caller_data.isCallType;
        extra_param['caller_user_id'] = this.caller_data.caller_user_id;
        extra_param['caller_user_role'] = this.caller_data.caller_user_role;
        extra_param['redirect'] = this.caller_data.redirect;
        let navigationExtras: NavigationExtras = {
          queryParams: extra_param
        };
        if(this.caller_data.isCallType==='audio'){
            this.router.navigate(['/audio-call'],navigationExtras);
        } else {
            this.router.navigate(['/video-call'],navigationExtras);
        }
      }
    });
  } 

}
